var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ElForm',{ref:"clusterNode",attrs:{"label-width":"240px","model":_vm.node,"inline":true},on:{"validate":function (field, result, error) { return _vm.checkNodeField(_vm.nodeIndex, field, result, error); }}},[_c('ElFormItem',{attrs:{"prop":"dnsName","label":("Node " + _vm.nodeIndex + " address:"),"rules":[
                {required: _vm.hostnameRequired, message: 'Node hostname is required'},
                { validator: _vm.validateNodeDns, trigger: 'blur'}
                ]}},[_c('ElInput',{attrs:{"placeholder":("DNS name " + (_vm.hostnameRequired ? '' : ' (optional)')),"autocomplete":"false","clearable":""},on:{"change":function($event){return _vm.resolveNodeDns()}},model:{value:(_vm.node.dnsName),callback:function ($$v) {_vm.$set(_vm.node, "dnsName", $$v)},expression:"node.dnsName"}})],1),_c('ElFormItem',{staticStyle:{"width":"130px"},attrs:{"prop":"extIp","rules":[
                {required: true, message: 'Node IP address is required'},
                {validator: _vm.validateNodeIp, trigger: 'blur'}
              ]}},[_c('ElInput',{attrs:{"placeholder":"External IP","autocomplete":"false","clearable":""},model:{value:(_vm.node.extIp),callback:function ($$v) {_vm.$set(_vm.node, "extIp", $$v)},expression:"node.extIp"}})],1),_c('transition',{attrs:{"name":"el-zoom-in-center"}},[_vm._v(", "),_c('ElFormItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.showIntIp),expression:"showIntIp"}],staticStyle:{"width":"130px"},attrs:{"prop":"intIp","rules":[
                {required: _vm.showIntIp, message: 'Node IP address is required'},
                {validator: _vm.validateNodeIp, trigger: 'blur'}
                ]}},[_c('ElInput',{attrs:{"placeholder":"Internal IP","autocomplete":"false","clearable":""},model:{value:(_vm.node.intIp),callback:function ($$v) {_vm.$set(_vm.node, "intIp", $$v)},expression:"node.intIp"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }